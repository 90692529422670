<template>
  <div class="landing-page">
    <div class="logo-section">
      <img src="@/assets/homeScreenLogoV0.png" alt="Logo" class="logo" />
    </div>

    <div class="form-section">
      <h1 class="title">O-OLEY</h1>
      <h2 class="subtitle">EYE HEALTH TEST</h2>
      <p class="subtext">Assess the quality of your vision</p>

      <form class="form-container">
        <label for="name">Your name:</label>
        <input v-model="name" type="text" id="name" placeholder="Type here" />

        <label for="age">Your age:</label>
        <input v-model="age" type="number" id="age" placeholder="Type here" />

        <label for="history">Known clinical history:</label>
        <input v-model="history" type="text" id="history" placeholder="Type here" />
      </form>

      <!-- Updated checkbox and T&C alignment -->
      <div class="terms-container">
        
        <label for="dataAgreed"> <input v-model="dataAgreed" type="checkbox" id="dataAgreed" /> I agree to the collection and storage of data for research purposes.</label>
      </div>

      <!-- Centered START button -->
      <div class="button-container">
        <button :disabled="!validForm" @click.prevent="submitInfo">START</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      age: '',
      history: '',
      dataAgreed: false,
    };
  },
  computed: {
    validForm() {
      return this.age && this.dataAgreed;
    },
  },
  methods: {
    startTest() {
      this.$router.push({
        name: 'TemporaryView',
        params: { name: this.name, age: this.age , history: this.history},
      });
    },
    submitInfo() {
      // Store the name and age in the Vuex store
      this.$store.dispatch('updateName', this.name);
      this.$store.dispatch('updateAge', this.age);
      this.$store.dispatch("updateHistory",this.history);
      // Navigate to the first instruction page
      this.$router.push({ name: 'InstructionPage1' });
    }
  },
};
</script>

<style scoped>
/* Layout for the landing page */
.landing-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

/* Logo Section */
.logo-section {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Logo Styling */
.logo {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

/* Form Section */
.form-section {
  text-align: center;
  width: 100%;
  max-width: 600px; /* Limit form width */
  margin: 0 auto; /* Center form section */
}

.form-container {
  background-color: var(--form-background-color);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
}

/* Form and Text Styling */
.title {
  font-size: 3.5rem;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 3rem;
  margin-bottom: 20px;
}

.subtext {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

label {
  margin: 10px 0 5px;
}

input[type="text"],
input[type="number"] {
  border: none;
  background-color: transparent;
  padding: 10px;
  font-size: 1.1rem;
  outline: none;
}

/* Corrected Checkbox and T&C alignment */
.terms-container {
  display: inline-block;
  margin-bottom: 20px;
}


/* Centered START button */
.button-container {
  text-align: center;
}

button {
  padding: 10px 30px;
  font-size: 1.8rem;
  background-color: var(--button-color);
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--button-hover-color); /* Hover effect */
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .logo-section {
    width: 50%;
  }

  .form-section {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .logo-section {
    width: 100%;
    display: none; /* Hide logo on smaller screens */
  }

  .form-section {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  input[type="text"],
  input[type="number"] {
    font-size: 1rem;
  }

  button {
    font-size: 1rem;
  }
}
</style>