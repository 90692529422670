<!-- src/App.vue -->
<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/example">Example Component</router-link>
    </nav> -->
    <router-view></router-view>  <!-- This will render the matched component -->
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* Normalize browser inconsistencies */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set a global font family and base font size */
html {
  font-size: 16px;  /* 1rem = 16px */
}

body {
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f9f9f9;
  line-height: 1.5;
}

/* Global link styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Global heading styles */
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  font-weight: 600;
}

/* Global button styles */
button {
  background-color: #b5b58b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Global input styles */
input {
  font-size: 1rem;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  width: 100%;
}

/* Light mode */
:root {
  --primary-color: #b5b58b;
  --background-color: #f9f9f9;
  --text-color: #333;
  --button-color: #b5b58b;  /* Normal button color */
  --button-hover-color: #a0a078;  /* Button hover color */
  --input-background: #fff;
  --form-background-color: #f0f0f5;
}

/* Dark mode */
[data-theme="dark"] {
  --primary-color: #4a4a4a;
  --background-color: #121212;
  --text-color: #e0e0e0;
  --button-color: #4a4a4a;  /* Button color in dark mode */
  --button-hover-color: #3b3b3b;  /* Button hover color in dark mode */
  --input-background: #333;
  --form-background-color: #2c2c2c;
}
</style>