// src/store.js
import { createStore } from 'vuex';

const store = createStore({
  state: {
    name: '',
    age: '',
    chartData: {
      labels: [
        'Central vision field',
        'Peripheral vision field',
        'Eye moisturization',
        'Color recognition',
        'Contrast sensitivity',
        'Color sensitivity',
      ],
      datasets: [
        {
          label: 'Vision Test Scores',
          backgroundColor: 'rgba(75, 192, 192, 0.4)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 2,
          data: [0, 0, 0, 0, 0, 0],  // Placeholder scores
        },
      ],
    },
    chartOptions : {
      responsive: true,  // Make it responsive
      maintainAspectRatio: false,  // Allow it to resize dynamically
      plugins: {
        legend: {
          display: false,  // Disable the legend
        },
        title: {
          display: false,  // Disable the title
        },
        tooltip: {
          enabled: true,
          bodyFont: {
            size: 16,  // Increase font size for tooltips
          },
        },
      },
      scales: {
        r: {
          ticks: {
            beginAtZero: true,
            max: 5,  // Max score is 5
            stepSize: 1,
            font: {
              size: 14,  // Increase font size for ticks
            },
          },
          pointLabels: {
            font: {
              size: 16,  // Increase font size for labels (axes)
            },
          },
        },
      },
    },
  },
  mutations: {
    setName(state, name) {
      state.name = name;
    },
    setAge(state, age) {
      state.age = age;
    },
    updateTestScore(state, { fieldIndex, score }) {
      state.chartData.datasets[0].data[fieldIndex] = score;
    },
    // New mutation to increment the score
    incrementTestScore(state, fieldIndex) {
      if (state.chartData.datasets[0].data[fieldIndex] !== undefined) {
        state.chartData.datasets[0].data[fieldIndex] += 1;
      }
    },
  },
  getters: {
    getChartData(state) {
      return state.chartData;
    },
    getChartOptions(state) {
      return state.chartOptions;
    },
    getName: (state) => state.name,
    getAge: (state) => state.age,
  },
  actions: {
    updateTestScore({ commit }, { fieldIndex, score }) {
      commit('updateTestScore', { fieldIndex, score });
    },
    // New action to increment score
    incrementTestScore({ commit }, fieldIndex) {
      commit('incrementTestScore', fieldIndex);
    },
    updateName({ commit }, name) {
      commit('setName', name);
    },
    updateAge({ commit }, age) {
      commit('setAge', age);
    }
  },
});

export default store;