// import Vue from 'vue';
// import VueRouter from 'vue-router';
import InfoInputPage from '@/components/InfoInputPage.vue';
// import TestStart from '@/components/TestStart.vue';
import { createRouter, createWebHashHistory } from 'vue-router'
// import InstructionPage1 from '@/components/InstructionPage1.vue';
// import InstructionPage2 from '@/components/InstructionPage2.vue';
// import InstructionPage3 from '@/components/InstructionPage3.vue';
// Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'InfoInputPage',
    component: InfoInputPage,
  },
  {
    path: '/instruction1',
    name: 'InstructionPage1',
    component: () => import("../components/InstructionPage1.vue"),
  },
  {
    path: '/instruction2',
    name: 'InstructionPage2',
    component: () => import("../components/InstructionPage2.vue"),
  },
  {
    path: '/instruction3',
    name: 'InstructionPage3',
    component: () => import("../components/InstructionPage3.vue"),
  },
  {
    path: '/dryeyetest',
    name: "DryEyeTest",
    component: () =>import("../components/DryEyeTest.vue"),
  },
  {
    path: "/colorblindtest",
    name: "ColorBlindTest",
    component: () => import("../components/ColorBlindTest.vue")
  },
  {
    path: "/constrastsensitivitytest",
    name: "ConstrastSensitivityTest",
    component: () => import("../components/ContrastSensitivityTest.vue")
  },
  {
    path: "/visualfieldtestA",
    name: "VisualFieldTestA",
    component: () => import("../components/VisualFieldTestA.vue")
  },
  {
    path: "/visualfieldtestB",
    name: "VisualFieldTestB",
    component: () => import("../components/VisualFieldTestB.vue")
  },
  {
    path: "/colorsensitivitytest",
    name: "ColorSensitivityTest",
    component: () => import("../components/ColorSensitivityTest.vue")
  },
  {
    path: "/resultpage",
    name: "ResultPage",
    component: () => import("../components/ResultPage.vue")
  },
];

// const router = new VueRouter({
//   routes,
// });

// export default router;
//


const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_API_URL),
  routes,
})

// const app = Vue.createApp({})
// app.use(router)


// import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

export default router
